import React, { useState, useEffect } from "react";
import styles from "./downloadModal.module.scss";
import moment from "moment";
import Checkbox from "../../checkbox";
import t from "./../../../_config/constants";
import Button from "../../button";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import toastService from "../../../_services/toast.service";
import { modalActions } from "../../../_store/actions/modal.actions";
import Input from "../../input";
import Selector from "../../selector";
import { makeSelectMap } from "../../../_utils/utils";
import { exportToCSV } from "../../../_utils/exportToCSV";
import { getStatistics } from "../../../_services/statistics.service";
import {
  mapDailyTransactions,
  mapMonthlyTransactions,
  getDropdownListMonths,
  isSameMonthAndYear,
  getDropdownListYears,
} from "../../../_utils/mapTransactions";
const DownloadModal = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [disableButton, setDisableButton] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState();
  const [monthsOptions, setMonthsOptions] = useState([]);
  const [yearsOptions, setYearsOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);

  const [selectedYear, setSelectedYear] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchRegions = async () => {
      let response = await dispatch(
        mainActions.run("data", "region", "all", {})
      );
      let arr = makeSelectMap(response);
      arr.unshift({ label: "All", value: "*" });
      setRegionOptions(arr);
    };
    fetchRegions();
    const arr = getDropdownListYears(true);
    setSelectedYear(arr[0].value);
    setYearsOptions(arr);
  }, []);
  useEffect(() => {
    if (store.auth && store.auth.user) {
      setSelectedRegion(store.auth.user.regionId);
    }
  }, [store.auth && store.auth.user]);

  useEffect(() => {
    if (selectedYear) {
      const arr = getDropdownListMonths(selectedYear);
      setSelectedMonth(arr[0].value);
      setMonthsOptions(arr);
    }
  }, [selectedYear]);

  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };

  const handleDownload = async () => {
    if (selectedMonth && selectedYear && selectedRegion) {
      setLoading(true);
      document.body.style.cursor = "wait";
      let result;
      let fileName =
        "Store Invoices Statistics - " +
        moment(selectedMonth.getTime()).format("MMMM YYYY");

      const firstDay = new Date(
        Date.UTC(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 1)
      ).getTime();
      const lastDay = new Date(
        Date.UTC(selectedMonth.getFullYear(), selectedMonth.getMonth() + 2, 0)
      ).getTime();
      result = await getStatistics({
        mode: "get-invoices",
        filter: { firstDay, lastDay, regionId: selectedRegion },
      });
      result.sort(function (a, b) {
        return a.Region > b.Region;
      });
      exportToCSV(result, fileName);
      setLoading(false);
      document.body.style.cursor = "auto";
      dispatch(modalActions.closeModal());
    }
  };
  return (
    <div className={styles.wrapper}>
      <h2>Download Store Invoices</h2>
      <div className={styles.select}>
        <div className={styles.selectMonth}>
          <Selector
            label="Month"
            multiselect={false}
            selectClassname="selectGray"
            selectedOption={getSelectedValue(monthsOptions, selectedMonth)}
            handleChangeSelect={(e) => setSelectedMonth(e.value)}
            options={monthsOptions}
            disabled={loading}
          />
        </div>
        <div className={styles.selectYear}>
          <Selector
            label="Year"
            multiselect={false}
            selectClassname="selectGray"
            selectedOption={getSelectedValue(yearsOptions, selectedYear)}
            handleChangeSelect={(e) => setSelectedYear(e.value)}
            disabled={loading}
            options={yearsOptions}
          />
        </div>
        {store?.auth?.user?.regionId === "*" && (
          <div className={styles.selectRegion}>
            <Selector
              label="Region"
              multiselect={false}
              selectClassname="selectGray"
              selectedOption={getSelectedValue(regionOptions, selectedRegion)}
              handleChangeSelect={(e) => setSelectedRegion(e.value)}
              disabled={loading}
              options={regionOptions}
            />
          </div>
        )}
      </div>
      <Button
        btnClass="btnNormalCasal"
        label="Download"
        onClick={handleDownload}
        disabled={disableButton}
      />
    </div>
  );
};
export default DownloadModal;
