import React, { useEffect, useState } from "react";
import styles from "./table.module.scss";
import t from "../../../../_config/constants";
import moment from "moment";
import Loading from "./../../../loading";
import { capitalizeWord } from "../../../../_utils/utils";
import useSortableData from "../../../../_utils/useSortableData";
import arrowUp from "../../../../_assets/icons/arrowUp.svg";
import arrowDown from "../../../../_assets/icons/arrowDown.svg";
import upDownArrow from "../../../../_assets/icons/upDownArrow.svg";
import Button from "../../../button";
import { modalActions } from "../../../../_store/actions/modal.actions";
import { useDispatch } from "react-redux";
import PayoutModal from "../payoutModal";

const PayoutsTable = ({
  payouts,
  loadingData,
  changeSortConfig,
  setSelected,
  userType,
}) => {
  const dispatch = useDispatch();
  const { handleSort, sortConfig } = useSortableData(payouts);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return upDownArrow;
    }
    return sortConfig.key === name
      ? sortConfig.direction === "ascending"
        ? arrowUp
        : arrowDown
      : upDownArrow;
  };
  useEffect(() => {
    changeSortConfig(sortConfig);
  }, [sortConfig]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p
          className={styles.blockUser}
          onClick={() => (loadingData ? null : handleSort("region.name"))}
        >
          Region
          <Button
            btnClass="btnBack"
            iconLeft={getClassNamesFor("region.name")}
          />
        </p>
        <p
          className={styles.blockDate}
          onClick={() => (loadingData ? null : handleSort("createdAt"))}
        >
          {t.common.text_date}{" "}
          <Button btnClass="btnBack" iconLeft={getClassNamesFor("createdAt")} />
        </p>
        <p
          className={styles.blockAmount}
          onClick={() => (loadingData ? null : handleSort("charges"))}
        >
          {t.common.text_th_charges}
          <Button btnClass="btnBack" iconLeft={getClassNamesFor("charges")} />
        </p>
        <p
          className={styles.blockAmount}
          onClick={() => (loadingData ? null : handleSort("tax_fees"))}
        >
          {t.common.text_th_tax_fees}
          <Button btnClass="btnBack" iconLeft={getClassNamesFor("tax_fees")} />
        </p>
        <p
          className={styles.blockAmount}
          onClick={() => (loadingData ? null : handleSort("refunds"))}
        >
          {t.common.text_th_refunds}
          <Button btnClass="btnBack" iconLeft={getClassNamesFor("refunds")} />
        </p>
        <p
          className={styles.blockAmount}
          onClick={() => (loadingData ? null : handleSort("stripeFees"))}
        >
          {t.common.text_th_stripe_fees}
          <Button
            btnClass="btnBack"
            iconLeft={getClassNamesFor("stripeFees")}
          />
        </p>
        <p
          className={styles.blockAmount}
          onClick={() => (loadingData ? null : handleSort("commission"))}
        >
          {t.common.text_th_commission}
          <Button
            btnClass="btnBack"
            iconLeft={getClassNamesFor("commission")}
          />
        </p>
        <p
          className={styles.blockAmount}
          onClick={() => (loadingData ? null : handleSort("amount"))}
        >
          {t.common.text_total}
          <Button btnClass="btnBack" iconLeft={getClassNamesFor("amount")} />
        </p>
        <p
          className={styles.blockStatus}
          onClick={() => (loadingData ? null : handleSort("payoutId"))}
        >
          {t.common.text_th_status}
          <Button btnClass="btnBack" iconLeft={getClassNamesFor("payoutId")} />
        </p>
        <p className={styles.blockAmount}></p>
      </div>
      {loadingData && (
        <div className={styles.loader}>
          <Loading width={75} height={75} />
        </div>
      )}
      {!loadingData && !payouts.length && (
        <div className={styles.noTransactionsMsg}>
          <span>No payouts found</span>
        </div>
      )}
      {!loadingData &&
        payouts.length > 0 &&
        payouts.map((item) => {
          return (
            <div className={styles.block} key={item._id}>
              <p className={styles.blockUser}>{item?.region?.name}</p>
              <p className={styles.blockDate}>
                {moment(item.createdAt)
                  .subtract(1, "days")
                  .format("MM/DD/YYYY")}
              </p>

              <p className={styles.blockAmount}>${item.charges}</p>
              <p className={styles.blockAmount}>
                ${Number(item.tax_fees).toFixed(2)}
              </p>
              <p className={styles.blockAmount}>-${item.refunds}</p>
              <p className={styles.blockAmount}>-${item.stripeFees}</p>
              <p className={styles.blockAmount}>-${item.commission}</p>
              <p className={styles.blockAmount}>
                ${item.amount} {item.currency?.toUpperCase()}
              </p>

              <p className={styles.blockButton}>
                {!item.payoutId ? (
                  userType === "master" ? (
                    <Button
                      btnClass="btnNormalCasal"
                      label={"Payout"}
                      onClick={() =>
                        dispatch(
                          modalActions.openModal(
                            <PayoutModal payout={item} />,
                            "",
                            "md"
                          )
                        )
                      }
                    />
                  ) : (
                    "Pending"
                  )
                ) : (
                  "Paid"
                )}
              </p>
              <p className={styles.blockButtonView}>
                <Button
                  btnClass="btnNormalCasal"
                  label={"Vew"}
                  onClick={() => {
                    setSelected(item);
                  }}
                />
              </p>
            </div>
          );
        })}
    </div>
  );
};
export default PayoutsTable;
