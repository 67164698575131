import React, { useState } from "react";
import styles from "./payoutModal.module.scss";
import moment from "moment";
import t from "./../../../../_config/constants";
import Button from "../../../button";
import { useDispatch } from "react-redux";
import toastService from "../../../../_services/toast.service";
import { modalActions } from "../../../../_store/actions/modal.actions";
import Input from "../../../input";
import { payoutRegion } from "../../../../_services/payment.service";
const PayoutModal = ({ payout, updateTable }) => {
  const [disableButton, setDisableButton] = useState(false);
  const dispatch = useDispatch();
  const handlePayout = async () => {
    setDisableButton(true);
    if (payout.id && payout.amount > 1) {
      const data = {
        transactionId: payout.id,
        conndectedAccountId: payout?.region?.conndectedAccountId,
      };
      try {
        const response = await payoutRegion(data);
        if (response) {
          if (response?.payout?.id) {
            toastService.show(
              "success",
              t.transactions.text_successfully_payout
            );
            payout.payoutId = response.payout.id;
          } else
            toastService.show(
              "error",
              `${t.common.text_something_went_wrong}! Please try again.`
            );
          dispatch(modalActions.closeModal());
        }
      } catch (err) {
        dispatch(modalActions.closeModal());
      }
    }
    setDisableButton(false);
  };
  return (
    <div className={styles.wrapper}>
      <h2>Payout - {payout?.region?.name}</h2>
      <div className={styles.info}>
        <div className={styles.invoiceInfo}>
          <p>
            {moment
              .utc(payout?.createdAt)
              .subtract(1, "months")
              .format("MMMM, YYYY")}
          </p>
          <p>
            Amount: {payout.currency?.slice(0, 2).toUpperCase()}${" "}
            {Number(payout.amount).toFixed(2)}
          </p>
        </div>
      </div>

      <div className={styles.viaStripe}>
        <Input
          label={"Amount"}
          inputClass="inputForm"
          type="number"
          name="amount"
          value={Number(payout.amount).toFixed(2)}
          disabled={true}
          desc={`${payout.currency?.slice(0, 2).toUpperCase()}$`}
        />

        {payout.amount >= 1 && payout.region.conndectedAccountId ? (
          <Button
            btnClass={"btnNormalCasal"}
            label={"Payout"}
            onClick={handlePayout}
            disabled={payout.amount < 1 || disableButton}
          />
        ) : !payout.region.conndectedAccountId ? (
          <span className={styles.cardError}>
            Unable to payout via Stripe - Connected Account not found
          </span>
        ) : (
          <span className={styles.cardError}>
            Unable to payout via Stripe - Min amount $1
          </span>
        )}
      </div>
    </div>
  );
};
export default PayoutModal;
