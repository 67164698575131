import React, { useState, useEffect } from "react";
import styles from "./transactions.module.scss";

import UpcomingPayouts from "../../_components/payouts/upcoming";
import RestaurantTable from "../../_components/transactions/restaurantTable";
import RestaurantTransactions from "../../_components/transactions/restaurantTransactions";

import useIsCleaner from "../../_utils/isCleaner";

import t from "../../_config/constants";

const Transactions = () => {
  const [activeTab, setActiveTab] = useState("info");

  const [step, setStep] = useState(1);
  const [user, setUser] = useState({});
  const isCleaner = useIsCleaner();
  useEffect(() => {
    isCleaner();
    setStep(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = (restaurant) => {
    setStep(2);
    setUser(restaurant);
  };

  const handleBack = () => {
    setStep(1);
    setUser({});
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.tabs}>
          <div
            className={`${
              activeTab === "info" ? styles.tabActive : styles.tabInactive
            }`}
            onClick={() => {
              setActiveTab("info");
            }}
          >
            <p>{t.transactions.text_financial_info}</p>
          </div>
          <div
            className={
              activeTab === "transactions"
                ? styles.tabActive
                : styles.tabInactive
            }
            onClick={() => {
              setActiveTab("transactions");
              setStep(1);
            }}
          >
            <p>Store Transactions</p>
          </div>
        </div>
        <div className={styles.content}>
          {activeTab === "info" ? (
            <UpcomingPayouts />
          ) : step === 1 ? (
            <RestaurantTable handleNext={handleNext} />
          ) : (
            <RestaurantTransactions handleBack={handleBack} restaurant={user} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Transactions;
