import httpService from "./http.service";

export const chargeUser = async (report) => {
  return httpService.apiRequest("post", "/api/payment", {
    mode: "off-session-charge",
    data: report,
  });
};
export const chargeInvoice = async (data) => {
  return httpService.apiRequest("post", "/api/payment", {
    mode: "off-session-charge-invoice",
    data: data,
  });
};
export const payoutRegion = async (data) => {
  return httpService.apiRequest("post", "/api/payment", {
    mode: "off-session-payout",
    data: data,
  });
};
export const refundUser = async (data) => {
  return httpService.apiRequest("post", "/api/payment", {
    mode: "refund",
    data: data,
  });
};

export const listRestaurantPaymentMethods = async (data) => {
  return httpService.apiRequest("post", "/api/payment", {
    mode: "list-restaurant-payment-methods",
    data: { user: data },
  });
};

export const createPromoCode = async (data) => {
  return httpService.apiRequest("post", "/api/payment", {
    mode: "create-promo-code",
    data: data,
  });
};

export const deletePromoCode = async (data) => {
  return httpService.apiRequest("post", "/api/payment", {
    mode: "delete-promo-code",
    data: data,
  });
};

export const addPaymentMethod = async (data) => {
  return httpService.apiRequest("post", "/api/payment", {
    mode: "add-payment-method",
    data: data,
  });
};

export const removePaymentMethod = async (data) => {
  return httpService.apiRequest("post", "/api/payment", {
    mode: "delete-payment-method",
    data: data,
  });
};
