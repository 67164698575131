import React from "react";
import PayoutsHistory from "../../_components/payouts/history";
import styles from "./payouts.module.scss";
const Payouts = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.header}>
          <h2>Payouts History</h2>
        </div>
        <div className={styles.content}>
          <PayoutsHistory />
        </div>
      </div>
    </div>
  );
};
export default Payouts;
