import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import {
  getDropdownListMonths,
  getDropdownListYears,
} from "../../../_utils/mapTransactions";
import Pagination from "../../pagination";
import Selector from "../../selector";
import TransactionsTable from "./table";
import SearchBar from "../../searchBar";
import styles from "./upcoming.module.scss";
import useDebounce from "../../../_utils/debounce";
import { termRegex } from "../../../_utils/utils";
import moment from "moment";
import Loading from "../../loading";
const UpcomingPayouts = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [stats, setStats] = useState({
    total: "0.00",
    successful: "0.00",
    failed: "0.00",
    pending: "0.00",
    refunded: "0.00",
  });
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingStats, setLoadingStats] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [numberOfTransactions, setNumberOfTransactions] = useState(0);
  const [yearsOptions, setYearsOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [monthsOptions, setMonthsOptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    status: "",
    transactionType: "",
    searchTerm: "",
  });
  const debouncedSearchTerm = useDebounce(filterOptions.searchTerm, 500);
  const [sortConfig, setSortConfig] = useState({});

  useEffect(() => {
    const arr = getDropdownListYears();
    setSelectedYear(arr[0].value);
    setYearsOptions(arr);
  }, []);
  useEffect(() => {
    setPageNumber(0);
  }, [
    debouncedSearchTerm,
    store.auth && store.auth.user,
    selectedMonth,
    filterOptions.status,
    filterOptions.transactionType,
  ]);

  useEffect(() => {
    const getTransactions = async (
      regionId,
      pageNumber,
      month,
      filterOptions,
      term,
      sortConfig = { createdAt: -1 }
    ) => {
      let filter = [];
      if (filterOptions.status && filterOptions.status != "*") {
        filter.push({ status: filterOptions.status });
      }
      if (
        filterOptions.transactionType &&
        filterOptions.transactionType != "*"
      ) {
        filter.push({ transactionType: filterOptions.transactionType });
      }
      if (regionId && regionId !== "*") {
        filter.push({ "user.regionId": regionId });
      }
      if (term) {
        filter.push({
          $or: [
            { "user.name": { $regex: `${termRegex(term)}`, $options: "i" } },
            { "user.email": { $regex: `${termRegex(term)}`, $options: "i" } },
            {
              "user.fullName": { $regex: `${termRegex(term)}`, $options: "i" },
            },
          ],
        });
      }
      let firstDay = month.getTime();
      let lastDay = new Date(
        month.getFullYear(),
        month.getMonth() + 1,
        1
      ).getTime();
      filter.push({
        transactionType: { $nin: ["SUBSCRIPTION", "PAYOUT"] },
        amount: { $nin: [0, "NaN"] },
        createdAt: { $gte: firstDay, $lte: lastDay },
      });

      let data = await dispatch(
        mainActions.run("transactions", "transaction", "page", {
          filter,
          pageSize,
          pageNumber,
          sort: sortConfig,
        })
      );
      if (!data) data = { rows: [], total: 0 };
      setTransactions(data.rows);

      setNumberOfTransactions(data.total);
      setStats(data.stats);
      setLoading(false);
      setLoadingStats(false);
    };
    if (store?.auth?.user?.regionId && selectedMonth) {
      setLoading(true);
      getTransactions(
        store.auth.user.regionId,
        pageNumber,
        selectedMonth,
        filterOptions,
        debouncedSearchTerm,
        sortConfig
      );
    }
  }, [
    store.auth && store.auth.user,
    debouncedSearchTerm,
    pageNumber,
    selectedMonth,
    filterOptions.status,
    filterOptions.transactionType,
    sortConfig,
  ]);
  useEffect(() => {
    setLoadingStats(true);
  }, [selectedMonth, store.auth && store.auth.user]);
  useEffect(() => {
    if (selectedYear) {
      const arr = getDropdownListMonths(selectedYear);
      setSelectedMonth(arr[0].value);
      setMonthsOptions(arr);
    }
  }, [selectedYear]);
  const onPageChange = (page) => {
    setPageNumber(page.selected);
  };
  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  const onChangeTerm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFilterOptions((prevState) => ({ ...prevState, [name]: value }));
  };
  const changeSortConfig = (data) => {
    if (data) {
      const { key, direction } = data;
      setSortConfig({ [key]: direction === "ascending" ? 1 : -1 });
    }
  };
  const statusOptions = [
    {
      label: "All",
      value: "*",
    },
    {
      label: "Successful",
      value: "SUCCESSFULL",
    },
    {
      label: "Pending",
      value: "PENDING",
    },
    {
      label: "Failed",
      value: "FAILED",
    },
  ];

  const transactionOptions = [
    {
      label: "All",
      value: "*",
    },
    {
      label: "Admin Charge",
      value: "ADMIN_CHARGE",
    },
    {
      label: "Penalty Charge",
      value: "CHARGE_LATE_FEES",
    },
    {
      label: "Refund",
      value: "REFUND",
    },
    { value: "RESTAURANT_INVOICE", label: "Monthly Invoice" },
    {
      label: "Subscription Rate",
      value: "SUBSCRIPTION_RATE",
    },
    {
      label: "Charged Container",
      value: "CHARGE_CONTAINER",
    },
    {
      label: "Per Use Charge",
      value: "PER_USE_CHARGE",
    },
    {
      label: "Per Use Report",
      value: "PER_USE_INVOICE",
    },
  ];
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.column}>
          <span className={styles.spanTitle}>Successful</span>
          {loadingStats ? (
            <Loading height={30} width={30} />
          ) : (
            <span className={styles.spanText}>${stats?.successful} CAD</span>
          )}
        </div>
        <div className={styles.column}>
          <span className={styles.spanTitle}>Pending</span>
          {loadingStats ? (
            <Loading height={30} width={30} />
          ) : (
            <span className={styles.spanText}>${stats?.pending} CAD</span>
          )}
        </div>

        <div className={styles.column}>
          <span className={styles.spanTitle}>Refunded</span>
          {loadingStats ? (
            <Loading height={30} width={30} />
          ) : (
            <span className={styles.spanText}>${stats?.refunded} CAD</span>
          )}
        </div>
        <div className={styles.column}>
          <span className={styles.spanTitle}>
            <span>Total</span>
            <span className={styles.date}>
              {moment(selectedMonth).format("MMMM,YYYY")}
            </span>
          </span>
          {loadingStats ? (
            <Loading height={24} width={24} />
          ) : (
            <span className={styles.spanText}>${stats?.total} CAD</span>
          )}
        </div>
      </div>
      <div className={styles.filter}>
        <div className={styles.searchBar}>
          <SearchBar
            customClass="restaurantSearch"
            placeholder={"Search user by name or email"}
            name="searchTerm"
            onChange={onChangeTerm}
            disabled={loading}
            value={filterOptions.searchTerm}
          />
        </div>
        <div className={styles.selects}>
          <div className={styles.selectType}>
            <Selector
              label=""
              multiselect={false}
              selectClassname="selectGray"
              selectedOption={getSelectedValue(
                transactionOptions,
                filterOptions.transactionType
              )}
              placeholder={"Transaction Type"}
              handleChangeSelect={(e) =>
                setFilterOptions((data) => ({
                  ...data,
                  transactionType: e.value,
                }))
              }
              disabled={loading}
              options={transactionOptions}
            />
          </div>
          <div className={styles.selectStatus}>
            <Selector
              label=""
              multiselect={false}
              selectClassname="selectGray"
              selectedOption={getSelectedValue(
                statusOptions,
                filterOptions.status
              )}
              placeholder={"Status"}
              handleChangeSelect={(e) =>
                setFilterOptions((data) => ({ ...data, status: e.value }))
              }
              disabled={loading}
              options={statusOptions}
            />
          </div>
          <div className={styles.selectDate}>
            <Selector
              label=""
              multiselect={false}
              selectClassname="selectGray"
              selectedOption={getSelectedValue(monthsOptions, selectedMonth)}
              handleChangeSelect={(e) => setSelectedMonth(e.value)}
              options={monthsOptions}
              disabled={loading}
            />
          </div>
          <div className={styles.selectDate}>
            <Selector
              label=""
              multiselect={false}
              selectClassname="selectGray"
              selectedOption={getSelectedValue(yearsOptions, selectedYear)}
              handleChangeSelect={(e) => setSelectedYear(e.value)}
              disabled={loading}
              options={yearsOptions}
            />
          </div>
        </div>
      </div>
      <TransactionsTable
        changeSortConfig={changeSortConfig}
        transactions={transactions}
        loadingData={loading}
      />

      <div className={styles.pagination}>
        <Pagination
          handlePageClick={onPageChange}
          pageCount={Math.ceil(numberOfTransactions / pageSize)}
          forcePage={pageNumber}
          loading={loading}
        />
      </div>
    </div>
  );
};
export default UpcomingPayouts;
