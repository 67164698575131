import React, { useState, useEffect } from "react";
import Button from "../../../../button";
import styles from "./downloadData.module.scss";
import t from "../../../../../_config/constants";
import { exportToCSV } from "../../../../../_utils/exportToCSV";
import documentService from "../../../../../_services/document.service";
const DownloadData = ({ payoutId }) => {
  const [data, setData] = useState([]);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [fileName, setFileName] = useState("");
  useEffect(() => {
    if (data.length > 0 && fileName) {
      exportToCSV(data, fileName);
    }
    setLoadingCSV(false);
  }, [data, fileName]);
  const handleDownload = async () => {
    if (payoutId) {
      setLoadingCSV(true);
      const response = await documentService.getPayoutTransactionsCSVData({
        payoutId,
      });
      setData(response.transactions);
      setFileName(response.fileName);
    }
  };
  return (
    <div className={styles.wrapper}>
      <Button
        disabled={loadingCSV}
        label={"Download Transactions"}
        btnClass="btnWhiteBackground"
        onClick={handleDownload}
      />
    </div>
  );
};
export default DownloadData;
