import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../pagination";
import styles from "./history.module.scss";
import { getDropdownListYears } from "../../../_utils/mapTransactions";
import PayoutsTable from "./table";
import SearchBar from "../../searchBar";
import Selector from "../../selector";
import useDebounce from "../../../_utils/debounce";
import { isObjectEmpty, termRegex } from "../../../_utils/utils";
import { mainActions } from "../../../_store/actions/main.actions";
import SinglePayout from "./single";

const PayoutsHistory = () => {
  const store = useSelector((state) => state);
  const [userType, setUserType] = useState({});
  const dispatch = useDispatch();
  const [selected, setSelected] = useState({});
  const [payouts, setPayouts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [numberOfPayouts, setNumberOfPayouts] = useState(0);
  const [yearsOptions, setYearsOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [filterOptions, setFilterOptions] = useState({
    status: "",
    searchTerm: "",
  });
  const [sortConfig, setSortConfig] = useState({});
  const debouncedSearchTerm = useDebounce(filterOptions.searchTerm, 500);
  useEffect(() => {
    setPageNumber(0);
  }, [
    debouncedSearchTerm,
    store.auth && store.auth.user,
    filterOptions.status,
    selectedYear,
  ]);
  useEffect(() => {
    const arr = getDropdownListYears();
    setSelectedYear(arr[0].value);
    setYearsOptions(arr);
  }, []);
  useEffect(() => {
    const getPayouts = async (
      regionId,
      pageNumber,
      year,
      filterOptions,
      term,
      sortConfig = { createdAt: -1 }
    ) => {
      let filter = [];
      if (filterOptions.status && filterOptions.status != "*") {
        filter.push({ status: filterOptions.status });
      }
      let userType = "master";
      if (regionId && regionId !== "*") {
        filter.push({ region_id: regionId });
        userType = "admin";
      }
      if (term) {
        filter.push({
          $or: [
            { "region.name": { $regex: `${termRegex(term)}`, $options: "i" } },
          ],
        });
      }
      let firstDay = new Date(year, 0).getTime();
      let lastDay = new Date(year + 1, 0).getTime();
      filter.push({
        transactionType: "PAYOUT",
        createdAt: { $gte: firstDay, $lte: lastDay },
      });

      let data = await dispatch(
        mainActions.run("transactions", "transaction", "page", {
          filter,
          pageSize,
          pageNumber,
          sort: sortConfig,
        })
      );
      if (!data) data = { rows: [], total: 0 };

      setPayouts(data.rows);

      setNumberOfPayouts(data.total);
      setUserType(userType);
      setLoading(false);
    };
    if (store?.auth?.user?.regionId && selectedYear) {
      setLoading(true);
      getPayouts(
        store.auth.user.regionId,
        pageNumber,
        selectedYear,
        filterOptions,
        debouncedSearchTerm,
        sortConfig
      );
    }
  }, [
    store.auth && store.auth.user,
    debouncedSearchTerm,
    pageNumber,
    selectedYear,
    filterOptions.status,
    filterOptions.transactionType,
    sortConfig,
  ]);
  const onPageChange = (page) => {
    setPageNumber(page.selected);
  };
  const onChangeTerm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFilterOptions((prevState) => ({ ...prevState, [name]: value }));
  };
  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  const changeSortConfig = (data) => {
    if (data) {
      const { key, direction } = data;
      setSortConfig({ [key]: direction === "ascending" ? 1 : -1 });
    }
  };
  const statusOptions = [
    {
      label: "All",
      value: "*",
    },
    {
      label: "Successful",
      value: "SUCCESSFULL",
    },
    {
      label: "Pending",
      value: "PENDING",
    },
    {
      label: "Failed",
      value: "FAILED",
    },
  ];
  return (
    <div className={styles.wrapper}>
      {isObjectEmpty(selected) ? (
        <>
          <div className={styles.filter}>
            <div className={styles.searchBar}>
              <SearchBar
                customClass="restaurantSearch"
                placeholder={"Search by name"}
                name="searchTerm"
                onChange={onChangeTerm}
                disabled={loading}
                value={filterOptions.searchTerm}
              />
            </div>
            <div className={styles.selects}>
              <div className={styles.selectStatus}>
                <Selector
                  label=""
                  multiselect={false}
                  selectClassname="selectGray"
                  selectedOption={getSelectedValue(
                    statusOptions,
                    filterOptions.status
                  )}
                  placeholder={"Status"}
                  handleChangeSelect={(e) =>
                    setFilterOptions((data) => ({ ...data, status: e.value }))
                  }
                  disabled={loading}
                  options={statusOptions}
                />
              </div>
              <div className={styles.selectDate}>
                <Selector
                  label=""
                  multiselect={false}
                  selectClassname="selectGray"
                  selectedOption={getSelectedValue(yearsOptions, selectedYear)}
                  handleChangeSelect={(e) => setSelectedYear(e.value)}
                  disabled={loading}
                  options={yearsOptions}
                />
              </div>
            </div>
          </div>
          <PayoutsTable
            changeSortConfig={changeSortConfig}
            payouts={payouts}
            loadingData={loading}
            setSelected={setSelected}
            userType={userType}
          />
          <div className={styles.pagination}>
            <Pagination
              handlePageClick={onPageChange}
              pageCount={Math.ceil(numberOfPayouts / pageSize)}
              forcePage={pageNumber}
              loading={loading}
            />
          </div>
        </>
      ) : (
        <SinglePayout payout={selected} handleClose={() => setSelected({})} />
      )}
    </div>
  );
};
export default PayoutsHistory;
