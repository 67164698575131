import React from "react";
import styles from "./single.module.scss";
import t from "../../../../_config/constants";
import moment from "moment";
import {
  getTransactionsType,
  sortPayoutTransactions,
} from "../../../../_utils/utils";
import DownloadData from "./downloadData";

const SinglePayout = ({ payout, handleClose }) => {
  const transactions = sortPayoutTransactions(payout.transactionList);
  const details = transactions.map((item, index) => {
    return (
      <tr key={index}>
        <td>{getTransactionsType(item.transactionType, true)}</td>
        <td>$ {Number(item.amount).toFixed(2)}</td>
      </tr>
    );
  });
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <span className={styles.close} onClick={handleClose}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </span>
        <h1>
          {payout.region.name},{" "}
          <span>
            {moment
              .utc(payout.createdAt)
              .subtract(1, "months")
              .format("MMMM YYYY")}
          </span>
        </h1>
        <div className={styles.table}>
          <table>
            <thead>
              <tr>
                <th>{t.common.text_transaction_type}</th>

                <th>{t.common.text_th_charges_and_tax}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
              </tr>{" "}
              {details}
            </tbody>
          </table>
          <div className={styles.summary}>
            <p className={styles.blockAmount}>
              <span>Revenue</span>${Number(payout.charges).toFixed(2)}
            </p>
            <p className={styles.blockAmount}>
              <span>Tax</span> ${Number(payout.tax_fees).toFixed(2)}
            </p>
            <p className={styles.blockAmount}>
              <span>Refunds</span>-${Number(payout.refunds).toFixed(2)}
            </p>
            <p className={styles.blockAmount}>
              <span>Stripe Fees*</span>-${Number(payout.stripeFees).toFixed(2)}
            </p>
            {payout.billingFee && (
              <p className={styles.blockAmount}>
                <span>Billing Subscriptions</span>-$
                {Number(payout.billingFee).toFixed(2)}
              </p>
            )}
            {payout.automaticTaxFee && (
              <p className={styles.blockAmount}>
                <span>Collecting Taxes</span>-$
                {Number(payout.automaticTaxFee).toFixed(2)}
              </p>
            )}
            <p className={styles.blockAmount}>
              <span>Commission ({payout.region.payoutCommission}%)</span>-$
              {Number(payout.commission).toFixed(2)}
            </p>
            <p className={styles.blockAmount}>
              <span>Total</span>${Number(payout.amount).toFixed(2)}
            </p>
          </div>
          <div className={styles.date}>
            Created:{" "}
            {moment
              .utc(payout.createdAt)
              .subtract(1, "days")
              .format("MM/DD/YYYY")}
          </div>
          <div className={styles.footnote}>
            <span>*Stripe Fees - 2.9% + 30¢ per transaction</span>
          </div>
        </div>
      </div>
      <DownloadData payoutId={payout._id} />
    </div>
  );
};
export default SinglePayout;
