import React, { useState, useEffect } from "react";
import styles from "./leftSidebar.module.scss";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../_store/actions/user.actions";
import { mainActions } from "../../_store/actions/main.actions";
import logoImg from "../../_assets/images/logoWhite.svg";
import logoSmall from "../../_assets/images/logoSmall.png";
import { ReactComponent as DashboardImg } from "../../_assets/icons/dashboardImg.svg";
import { ReactComponent as ContainerManagementImg } from "../../_assets/icons/ContainerManagement.svg";
import { ReactComponent as RestaurantManagementImg } from "../../_assets/icons/RestaurantManagement.svg";
import { ReactComponent as UserManagementImg } from "../../_assets/icons/UserManagement.svg";
import { ReactComponent as TransactionsImg } from "../../_assets/icons/transactionsImg.svg";
import { ReactComponent as DistributionImg } from "../../_assets/icons/distributionImg.svg";
import { ReactComponent as CleaningImg } from "../../_assets/icons/cleaningImg.svg";
import { ReactComponent as CleanerManagementImg } from "../../_assets/icons/CleanerManagement.svg";
import { ReactComponent as NotificationImg } from "../../_assets/icons/notificationImg.svg";
import { ReactComponent as RefundImg } from "../../_assets/icons/refundImg.svg";
import { ReactComponent as LogoutImg } from "../../_assets/icons/logoutImg.svg";
import { ReactComponent as AdminImg } from "../../_assets/icons/user.svg";
import { ReactComponent as DropoffImg } from "../../_assets/icons/Drop-offManagement.svg";
import { ReactComponent as ReportImg } from "../../_assets/icons/Report.svg";
import { ReactComponent as CouponImg } from "../../_assets/icons/Promo1.svg";
import { ReactComponent as SettingsImg } from "../../_assets/icons/settings.svg";
import { ReactComponent as ReuserImg } from "../../_assets/icons/reuser.svg";
import { RiShieldKeyholeFill } from "react-icons/ri";
import t from "../../_config/constants";
const LeftSidebar = () => {
  const store = useSelector((state) => state);
  const [user, setUser] = useState({});
  const [unread, setUnread] = useState(0);
  const [showFinance, setShowFinance] = useState(false);
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(userActions.logout());
  };
  const getNotifications = async (id) => {
    setUnread(0);
    let filter = [];
    if (id && id !== "*") {
      filter.push({
        regionId: id,
        seen: false,
      });
    } else {
      filter.push({
        seen: false,
      });
    }
    let data = await dispatch(
      mainActions.run("notifications", "notification", "all", { filter })
    );
    if (data) {
      setUnread(data.length);
    }
  };
  useEffect(() => {
    if (store && store.auth && store.auth.user) {
      setUser(store.auth.user);
      getNotifications(store.auth.user.regionId);
    }
  }, [store && store.auth && store.auth.user]);
  useEffect(() => {
    if (store.main?.notifications?.notification?.list) {
      setUnread(store.main?.notifications?.notification?.list.length);
    }
  }, [
    store.auth && store.auth.user,
    store.main.notifications &&
      store.main.notifications.notification &&
      store.main.notifications.notification.list,
  ]);

  return (
    <section className={styles.wrapper}>
      <ul>
        <li>
          <NavLink to="/dashboard" className={styles.logo}>
            <div className={styles.smallLogo}>
              <img src={logoSmall} />
            </div>
            <div className={styles.normalLogo}>
              <img src={logoImg} />
            </div>
          </NavLink>
        </li>
        <div className={styles.routes}>
          {user &&
            user.type &&
            user.type !== "cleaner" &&
            user.subType !== "subregionAdmin" && (
              <>
                <li>
                  <NavLink
                    to={
                      user.type && user.type !== "cleaner"
                        ? "/dashboard"
                        : "/cleaning"
                    }
                    className={`${styles.parent}`}
                    activeClassName={styles.activeLinkParent}
                  >
                    <DashboardImg />
                    <div className={styles.name}>
                      <span>{t.sidebar.text_dashboard}</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/reports"
                    className={`${styles.parent}`}
                    activeClassName={styles.activeLinkParent}
                  >
                    <ReportImg />
                    <div className={styles.name}>
                      <span>{t.sidebar.text_reports}</span>
                    </div>
                  </NavLink>
                </li>
                {user && user.type && user.type === "master" && (
                  <li>
                    <NavLink
                      to="/admin"
                      className={`${styles.parent}`}
                      activeClassName={styles.activeLinkParent}
                    >
                      <AdminImg />
                      <div className={styles.name}>
                        <span>{t.sidebar.text_admin}</span>
                      </div>
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    to="/container-management"
                    className={`${styles.parent}`}
                    activeClassName={styles.activeLinkParent}
                  >
                    <ContainerManagementImg />
                    <div className={styles.name}>
                      <span>{t.sidebar.text_container}</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/dropoff-management"
                    className={`${styles.parent}`}
                    activeClassName={styles.activeLinkParent}
                  >
                    <DropoffImg />
                    <div className={styles.name}>
                      <span>{t.sidebar.text_dropoff}</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/store-management"
                    className={`${styles.parent}`}
                    activeClassName={styles.activeLinkParent}
                  >
                    <RestaurantManagementImg />
                    <div className={styles.name}>
                      <span>{t.sidebar.text_restaurat}</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/user-management"
                    className={`${styles.parent}`}
                    activeClassName={styles.activeLinkParent}
                  >
                    <UserManagementImg />
                    <div className={styles.name}>
                      <span>{t.sidebar.text_member}</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/coupon-management"
                    className={`${styles.parent}`}
                    activeClassName={styles.activeLinkParent}
                  >
                    <CouponImg />
                    <div className={styles.name}>
                      <span>{t.sidebar.text_coupon}</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/cleaners-management"
                    className={`${styles.parent}`}
                    activeClassName={styles.activeLinkParent}
                  >
                    <CleanerManagementImg />
                    <div className={styles.name}>
                      <span>{t.sidebar.text_cleaner}</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <div
                    onClick={() => setShowFinance((prev) => !prev)}
                    className={`${styles.parent} ${styles.parentTransactions} ${
                      showFinance ? styles.activeLinkTransactions : ""
                    }`}
                  >
                    <TransactionsImg />
                    <div className={styles.name}>
                      <span>{t.sidebar.text_finance}</span>
                    </div>
                  </div>
                  {showFinance && (
                    <ul>
                      <li>
                        <NavLink
                          to="/transactions"
                          className={`${styles.child}`}
                          activeClassName={styles.activeLinkParent}
                        >
                          <TransactionsImg />
                          <div className={styles.name}>
                            <span>{t.sidebar.text_transactions}</span>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/report"
                          className={`${styles.child}`}
                          activeClassName={styles.activeLinkParent}
                        >
                          <ReportImg />
                          <div className={styles.name}>
                            <span>{t.sidebar.text_report}</span>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/payouts"
                          className={`${styles.child}`}
                          activeClassName={styles.activeLinkParent}
                        >
                          <RefundImg />
                          <div className={styles.name}>
                            <span>{t.sidebar.text_payouts}</span>
                          </div>
                        </NavLink>
                      </li>{" "}
                      <li>
                        <NavLink
                          to="/refund"
                          className={`${styles.child}`}
                          activeClassName={styles.activeLinkParent}
                        >
                          <RefundImg />
                          <div className={styles.name}>
                            <span>{t.sidebar.text_refund}</span>
                          </div>
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              </>
            )}
          {user && user.subType !== "subregionAdmin" && (
            <>
              <li>
                <NavLink
                  to="/distribution"
                  className={`${styles.parent}`}
                  activeClassName={styles.activeLinkParent}
                >
                  <DistributionImg />
                  <div className={styles.name}>
                    <span>{t.sidebar.text_distribution}</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/cleaning"
                  className={`${styles.parent}`}
                  activeClassName={styles.activeLinkParent}
                >
                  <CleaningImg />
                  <div className={styles.name}>
                    <span>{t.sidebar.text_cleaning}</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/notifications"
                  className={`${styles.parent}`}
                  activeClassName={styles.activeLinkParent}
                >
                  <NotificationImg />
                  <div className={styles.name}>
                    <span>
                      {t.sidebar.text_notifications}{" "}
                      {unread !== 0 && `(${unread})`}
                    </span>
                  </div>
                </NavLink>
              </li>
            </>
          )}
          {user && user.subType === "subregionAdmin" && (
            <>
              <li>
                <NavLink
                  to="/containers-stats"
                  className={`${styles.parent}`}
                  activeClassName={styles.activeLinkParent}
                >
                  <DashboardImg />
                  <div className={styles.name}>
                    <span>{t.sidebar.text_container_stats}</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/impact"
                  className={`${styles.parent}`}
                  activeClassName={styles.activeLinkParent}
                >
                  <CleaningImg />
                  <div className={styles.name}>
                    <span>{t.sidebar.text_impact}</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/op-reusers"
                  className={`${styles.parent}`}
                  activeClassName={styles.activeLinkParent}
                >
                  <ReuserImg />
                  <div className={styles.name}>
                    <span>{t.sidebar.text_reusers}</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/container-usage"
                  className={`${styles.parent}`}
                  activeClassName={styles.activeLinkParent}
                >
                  <ContainerManagementImg />
                  <div className={styles.name}>
                    <span>{t.sidebar.text_usage}</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/op-notifications"
                  className={`${styles.parent}`}
                  activeClassName={styles.activeLinkParent}
                >
                  <NotificationImg />
                  <div className={styles.name}>
                    <span>{t.sidebar.text_notifications}</span>
                  </div>
                </NavLink>
              </li>
            </>
          )}
          {user && user.type && user.type === "master" && (
            <>
              <li>
                <NavLink
                  to="/permissions"
                  className={`${styles.parent}`}
                  activeClassName={styles.activeLinkParent}
                >
                  <RiShieldKeyholeFill />
                  <div className={styles.name}>
                    <span>{t.sidebar.text_permissions}</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/settings"
                  className={`${styles.parent}`}
                  activeClassName={styles.activeLinkParent}
                >
                  <SettingsImg />
                  <div className={styles.name}>
                    <span>{t.sidebar.text_settings}</span>
                  </div>
                </NavLink>
              </li>
            </>
          )}
        </div>
        <div
          className={user.subType === "subregionAdmin" ? styles.logoutLi : ""}
        >
          {user.subType === "subregionAdmin" && (
            <li>
              <div className={styles.support}>
                <a href="https://app.reusables.com/support" target="_blank">
                  Get Support
                </a>{" "}
              </div>
            </li>
          )}
          <li>
            <div onClick={logout} className={styles.logout}>
              {user.subType !== "subregionAdmin" && <LogoutImg />}
              <div className={styles.name}>
                <span>{t.sidebar.text_logout}</span>
              </div>
            </div>
          </li>
        </div>
      </ul>
    </section>
  );
};

export default LeftSidebar;
