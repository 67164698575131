import React, { useState, useEffect } from "react";
import styles from "./regionNotifications.module.scss";
import Button from "../../button";
import Textarea from "../../textarea";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import Loading from "../../loading";
import toastService from "../../../_services/toast.service";
import t from "../../../_config/constants";
import { isObjectEmpty } from "../../../_utils/utils";
import trimObjectProps from "../../../_utils/trimObjectProps";
const RegionNotifications = ({
  onRegionClick,
  onSubRegionClick,
  onAdminClick,
  onNotificationsClick,
  onPostalCodesClick,
  activeTab,
}) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [selected, setSelected] = useState({});
  const [daysToReturnArr, setDaysToReturnArr] = useState({});
  const [loading, setLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const [errorMsg, setErrorMsg] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (
      store.main &&
      store.main.data &&
      store.main.data.region &&
      store.main.data.region.list
    ) {
      setRegions(store.main.data.region.list);
    }
    setLoading(false);
  }, [store.main.data && store.main.data.region]);
  const getAllRegions = async () => {
    let response = await dispatch(mainActions.run("data", "region", "all", {}));
    setRegions(response);
    setLoading(false);
  };
  const handleChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    setSelected((selected) => ({
      ...selected,
      notifications: { ...selected.notifications, [name]: value },
    }));
  };

  const handleCancel = () => {
    setSelected({});
    setDaysToReturnArr({});
  };

  const handleSubmit = async () => {
    trimObjectProps(selected.notifications);
    setDisabled(true);
    let response = await dispatch(
      mainActions.run("data", "region", "update", selected)
    );
    if (response)
      toastService.show("success", t.toast.text_successfully_submited);
    getAllRegions();
    handleCancel();
    setDisabled(false);
  };

  const handleSelected = (item) => {
    setSelected(item);
    let arr = Array.from(Array(Number(item.days_to_return)).keys()).sort(
      (a, b) => b - a
    );
    arr.push(-1);
    arr = arr?.map((ind) => {
      let visible =
        ind == 0 ? item?.notifications[ind] : item?.notifications[-ind];
      return {
        day: ind,
        visible: visible,
      };
    });
    setDaysToReturnArr(arr);
  };

  const handleShowTextArea = (item) => {
    let arr = [...daysToReturnArr];
    let el = arr.find((arrItem) => arrItem.day === item.day);
    el.visible = !el.visible;
    setDaysToReturnArr(arr);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.header}>
          <p
            className={
              activeTab === "region" ? styles.tabActive : styles.tabInactive
            }
            onClick={onRegionClick}
          >
            {t.admin.regions.text_regions}
          </p>
          <p
            className={
              activeTab === "subregion" ? styles.tabActive : styles.tabInactive
            }
            onClick={onSubRegionClick}
          >
            {t.admin.regions.text_subregions}
          </p>
          <p
            className={
              activeTab === "codes" ? styles.tabActive : styles.tabInactive
            }
            onClick={onPostalCodesClick}
          >
            {t.admin.regions.text_region_postal_codes}
          </p>
          <p
            className={
              activeTab === "notifications"
                ? styles.tabActive
                : styles.tabInactive
            }
            onClick={onNotificationsClick}
          >
            {t.admin.regions.text_region_notifications}
          </p>
          <p
            className={
              activeTab === "admin" ? styles.tabActive : styles.tabInactive
            }
            onClick={onAdminClick}
          >
            {t.admin.admins.text_admins}
          </p>
        </div>
        <h2>{t.admin.regions.text_list_of_regions}</h2>
        {loading && <Loading width={50} height={50} />}
        {regions && regions.length > 0 && (
          <div className="custom-table">
            <table>
              <thead>
                <tr className="tb-head-th">
                  <th>Name</th>
                  <th>Charging option</th>
                  <th>{t.admin.regions.text_th_return_days_premium}</th>
                  <th>{t.admin.regions.text_th_return_days_free}</th>
                </tr>
              </thead>
              <tbody>
                {regions?.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <tr
                      onClick={() => handleSelected(item)}
                      className={
                        selected._id === item._id ? styles.selected : ""
                      }
                    >
                      <td>{item.name}</td>
                      <td>
                        {item.chargingOption === "full"
                          ? "Charge full price"
                          : "Late fee per day"}
                      </td>
                      <td>{item.days_to_return_premium}</td>
                      <td>{item.days_to_return}</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {!loading && regions.length < 1 && (
          <p>{t.admin.notifications.text_no_notifications}</p>
        )}
      </div>
      <div className={styles.right}>
        {!isObjectEmpty(selected) ? (
          <>
            <h2>{t.admin.notifications.text_edit}</h2>
            <div className={styles.content}>
              {daysToReturnArr.length > 0 &&
                daysToReturnArr?.map((item) => (
                  <div key={item.day} className={styles.textarea}>
                    <label
                      onClick={() => {
                        handleShowTextArea(item);
                      }}
                    >
                      <span>
                        {item.day === 0
                          ? "Due Date"
                          : item.day < 0
                          ? "Overdue (sent every day)"
                          : `${item.day} ${
                              item.day === 1 ? "day" : "days"
                            } before the due date`}
                      </span>
                      <div className={styles.plus}>
                        {item.visible ? "-" : "+"}
                      </div>
                    </label>
                    {item.visible && (
                      <Textarea
                        name={
                          item.day === 0
                            ? "0"
                            : item.day < 0
                            ? "1"
                            : `-${item.day}`
                        }
                        textareaClass="textareaDefault"
                        type="text"
                        onChange={handleChange}
                        value={
                          selected.notifications[
                            item.day === 0
                              ? item.day
                              : item.day < 0
                              ? "1"
                              : `-${item.day}`
                          ]
                        }
                      />
                    )}
                  </div>
                ))}
            </div>
            {errorMsg && (
              <div className={styles.error}>
                <p>{t.admin.regions.text_all_fields_required}</p>
              </div>
            )}
            <div className={styles.options}>
              <div className={styles.submit}>
                <Button
                  btnClass="btnWhiteBackground"
                  label={t.button.text_cancel}
                  onClick={handleCancel}
                />
                <Button
                  btnClass="btnNormalCasal"
                  label={t.button.text_save}
                  onClick={handleSubmit}
                  disabled={disabled}
                />
              </div>
            </div>
          </>
        ) : (
          <div className={styles.noData}>
            <span>{t.admin.notifications.text_select_guideline} </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegionNotifications;
